import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-plugin-sanity-image"

import Layout from "../../components/layout"

const ClassesPage = ({ data }) => {
  const classes = data.allSanityClass.nodes

  return (
    <Layout>
      <div className="bg-texture px-6 lg:px-0 overflow-hidden">
        <div className="mt-12 mx-auto">
          <h1
            className="text-center text-mocha font-display text-5xl"
            style={{ fontWeight: 700 }}
          >
            Classes
          </h1>
        </div>

        <div className="sm:mt-5 max-w-7xl mx-auto flex flex-wrap justify-center space-y-8 space-x-0 sm:space-y-6 sm:space-x-6 lg:space-y-8 lg:space-x-8">
          {classes.map(_class => {
            return (
              <>
                {_class.name != "Hero Spells" ? (
                  <div className="relative group w-full sm:w-2/5 md:w-3/12">
                    <Link to={`/classes/${_class.slug.current}`}>
                      <div className="h-96 sm:h-112">
                        <Image
                          {..._class.image}
                          alt={`${_class.name} Avatar`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="absolute bottom-0 w-full h-12 flex items-center justify-center bg-mocha group-hover:bg-mocha-light transition duration-300 ease-in-out"
                        style={{ borderRadius: "0.5rem" }}
                      >
                        <span className="text-center text-xl text-white font-display tracking-wide">
                          {_class.name}
                        </span>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ClassesPage

export const query = graphql`
  query AllClasses {
    allSanityClass(sort: { fields: [name], order: ASC }) {
      nodes {
        _id
        name
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
